import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from './createContext';
import { sample } from 'lodash';

const footerOptions = [
  'city',
  'streets',
  'parks',
  'corner',
  'neighborhood',
  'schools',
  'block',
  'community',
  'playground',
  'power',
  'lights',
];

// The provider, which holds the page-wide store and its actions.
// Feel free to abstract actions and state away from this file.
class AppProvider extends Component {
  state = {
    open: false,
    showModal: () => this.setState({ open: true }),
    hideModal: () => this.setState({ open: false }),
    slideDrawing: 0,
    setSlideDrawing: order => this.setState({ slideDrawing: order }),
    menuOpen: false,
    showMenu: () => this.setState({ menuOpen: true }),
    hideMenu: () => {
      this.setState({ menuOpen: false });
      if (typeof document !== 'undefined') {
        window.scrollBy(0, 1);
      }
    },
    menuColor: 'black',
    setMenuColor: color => this.setState({ menuColor: color }),
    footerOpen: false,
    footerMessage: 'city',
    showFooter: () => this.setState({ footerOpen: true }),
    filterCaseStudies: null,
    filterJournal: null,
    filterProjects: null,
    searchJournal: '',
    changeSearchJournal: search => {
      this.setState({ searchJournal: search });
    },
    changeFilterCaseStudies: filter => this.setState({ filterCaseStudies: filter }),
    changeFilterJournal: filter => this.setState({ filterJournal: filter }),
    changeFilterProjects: filter => this.setState({ filterProjects: filter }),
    changePage: menuColor =>
      this.setState({
        menuOpen: false,
        menuColor: menuColor,
        filterCaseStudies: null,
        filterJournal: null,
        footerOpen: false,
        searchJournal: '',
        footerMessage: sample(footerOptions),
      }),
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
